import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import AboutUs from './components/AboutUs';
import Location from './components/Location';
import FAQ from './components/FAQ';
import RSVP from './components/RSVP';
import Header from './components/Header';
import { throttle } from 'lodash';
import { FlagGB, FlagIL } from './components/Flags';
import Login, { valideAccessCode } from './components/Login';

const App: React.FC = () => {
    const [language, setLanguage] = useState<'english' | 'hebrew'>('english');
    const containerRef = useRef<HTMLDivElement>(null);
    const [headerInView, setHeaderInView] = useState(true);
    const [endInView, setEndInView] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const accessCode = localStorage.getItem('accessCode');
        if (accessCode && valideAccessCode(accessCode)) {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        const userLang = navigator.language || navigator.languages[0];
        if (userLang === 'he' || userLang === 'he-IL') {
            setLanguage('hebrew');
        } else {
            setLanguage('english');
        }
    }, []);

    useEffect(() => {
        const handleScroll = throttle(() => {
            const container = containerRef.current;
            if (container) {
                const firstSection = container.querySelector('.header-section');
                const lastSection = container.querySelector('.rsvp-section');
                const containerRect = container.getBoundingClientRect();

                if (firstSection) {
                    const rect = firstSection.getBoundingClientRect();
                    const isVisible =
                        rect.left >= containerRect.left &&
                        rect.right <= containerRect.right;
                    setHeaderInView(isVisible);
                } else {
                    setHeaderInView(false);
                }

                if (lastSection) {
                    const rect = lastSection.getBoundingClientRect();
                    const isVisible =
                        rect.left >= containerRect.left &&
                        rect.right <= containerRect.right;
                    setEndInView(isVisible);
                } else {
                    setEndInView(false);
                }

                const sections = container.querySelectorAll('section');
                sections.forEach((section) => {
                    const rect = section.getBoundingClientRect();
                    if (rect.left == containerRect.left) {
                        const sectionId = section.getAttribute('id');
                        if (sectionId) {
                            window.history.replaceState(
                                null,
                                '',
                                `#${sectionId}`,
                            );
                        }
                    }
                });
            }
        }, 1000); // Throttle to 1 call per second

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    });

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const target = document.querySelector(hash);
            if (target) {
                target.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    const scrollToNextSection = () => {
        const container = containerRef.current;
        if (container) {
            const containerRect = container.getBoundingClientRect();
            const sections = Array.from(container.querySelectorAll('section'));

            const currentSection = sections.find((section) => {
                const rect = section.getBoundingClientRect();
                return language === 'english'
                    ? rect.left >= containerRect.left
                    : rect.left <= containerRect.left;
            });

            if (currentSection) {
                const nextSectionIndex = sections.indexOf(currentSection) + 1;
                if (nextSectionIndex < sections.length) {
                    const nextSection = sections[nextSectionIndex];
                    nextSection.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };

    const scrollToPreviousSection = () => {
        const container = containerRef.current;
        if (container) {
            const containerRect = container.getBoundingClientRect();
            const sections = Array.from(container.querySelectorAll('section'));

            const currentSection = sections.find((section) => {
                const rect = section.getBoundingClientRect();
                return language === 'english'
                    ? rect.left >= containerRect.left
                    : rect.left <= containerRect.left;
            });

            if (currentSection) {
                const previousSectionIndex =
                    sections.indexOf(currentSection) - 1;
                if (previousSectionIndex >= 0) {
                    const previousSection = sections[previousSectionIndex];
                    previousSection.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };

    const toggleLanguage = () => {
        setLanguage(language === 'english' ? 'hebrew' : 'english');
        document
            .querySelector('#welcome')
            ?.scrollIntoView({ behavior: 'smooth' });
    };

    if (!isAuthenticated) {
        return (
            <div className={`app-wrapper ${language}`}>
                <div className="app-overlay" />
                <div
                    className={`language-toggle ${language}`}
                    onClick={toggleLanguage}
                >
                    {language === 'english' ? <FlagIL /> : <FlagGB />}
                </div>
                <Login
                    onLogin={() => setIsAuthenticated(true)}
                    language={language}
                />
            </div>
        );
    }

    return (
        <div className={`app-wrapper ${language}`}>
            <div className="app-overlay" />
            <div
                className={`language-toggle ${language}`}
                onClick={toggleLanguage}
            >
                {language === 'english' ? <FlagIL /> : <FlagGB />}
            </div>

            <div className="app-container" ref={containerRef}>
                <Header
                    language={language}
                    headerInView={headerInView}
                    endInView={endInView}
                    onSwipeLeft={scrollToNextSection}
                    onSwipeRight={scrollToPreviousSection}
                />
                <AboutUs language={language} />
                <Location language={language} />
                <FAQ language={language} />
                <RSVP language={language} />
            </div>
        </div>
    );
};

export default App;
