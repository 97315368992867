import React, { useState } from 'react';
import './Login.css';

type LoginProps = {
    language: 'english' | 'hebrew';
    onLogin: () => void;
};

export const valideAccessCode = (access_code: string) => {
    const accessCodeEn = process.env.REACT_APP_ACC_CODE;
    const accessCodeHe = process.env.REACT_APP_ACC_CODE_HE;
    return (
        access_code.replaceAll(' ', '').toLowerCase() === accessCodeEn ||
        access_code.replaceAll(' ', '').toLowerCase() == accessCodeHe
    );
};

const Login: React.FC<LoginProps> = ({ onLogin, language }) => {
    const [accessCode, setAccessCode] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (valideAccessCode(accessCode)) {
            localStorage.setItem('accessCode', accessCode);
            onLogin();
        } else {
            alert(
                language === 'english'
                    ? 'Incorrect access code'
                    : 'קוד גישה שגוי',
            );
        }
    };

    return (
        <div className={`login-container ${language}`}>
            <form onSubmit={handleSubmit} className="login-container">
                <h2>
                    {language == 'english'
                        ? 'Enter Access Code'
                        : 'הזינו קוד מההזמנה'}
                </h2>
                <input
                    className={`code-input ${language}`}
                    id="password"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                />
                <button className="blue-button" type="submit">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default Login;
