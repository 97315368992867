export const FlagIL = () => (
  <svg viewBox="0 55.7 512 400.6" height={25}>
    <g transform="matrix(1, 0, 0, 1, 3.552713678800501e-15, 3.552713678800501e-15)">
      <path d="M512 55.7H0v66.7h512V55.7Zm0 333.9H0v66.7h512v-66.7Z" />
      <path d="M352.4 200.3H288l-32-55.6-32.1 55.6h-64.3l32.1 55.7-32 55.7h64.2l32.1 55.6 32.1-55.6h64.3L320.3 256l32-55.7Zm-57 55.7-19.7 34.2h-39.4L216.5 256l19.8-34.2h39.4l19.8 34.2ZM256 187.6l7.3 12.7h-14.6l7.3-12.7Zm-59.2 34.2h14.7l-7.4 12.7-7.3-12.7Zm0 68.4 7.3-12.7 7.4 12.7h-14.7Zm59.2 34.2-7.3-12.7h14.6l-7.3 12.7Zm59.2-34.2h-14.7l7.4-12.7 7.3 12.7Zm-14.7-68.4h14.7l-7.3 12.7-7.4-12.7Z" />
    </g>
  </svg>
);

export const FlagGB = () => (
  <svg viewBox="0 0 640 480" height={25}>
    <path
      d="M0 0h640v480H0z"
      transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)"
    />
    <path
      fill="#fff"
      d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"
      transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)"
    />
    <path
      d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"
      transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)"
    />
    <path
      fill="#fff"
      d="M241 0v480h160V0zM0 160v160h640V160z"
      transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)"
    />
    <path
      d="M0 193v96h640v-96zM273 0v480h96V0z"
      transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)"
    />
  </svg>
);
