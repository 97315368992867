import React from 'react';
import './Header.css';
import arrow from '../assets/arrow.png';
import SantoriniVector from '../assets/santoriniVector';

type HeaderProps = {
    language: 'english' | 'hebrew';
    headerInView: boolean;
    endInView: boolean;
    onSwipeLeft: () => void;
    onSwipeRight: () => void;
};

const calculateDays = () => {
    const today = new Date();
    const timeDiff = weddingDate.getTime() - today.getTime();
    const daysUntilWedding = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysUntilWedding;
};

const weddingDate = new Date('2025-09-27');
const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
};
const daysLeft = calculateDays();

const Header: React.FC<HeaderProps> = ({
    language,
    headerInView,
    endInView,
    onSwipeLeft,
    onSwipeRight,
}) => {
    console.log('headerinview', headerInView);
    console.log('endInView', endInView);
    const locale = language === 'hebrew' ? 'he-IL' : 'en-GB';

    return (
        <section id="welcome" className="header-section">
            <div
                className={`swipe-container ${language} ${
                    !endInView ? 'visible' : 'hidden'
                }`}
            >
                <div className={`swipe-left ${language}`} onClick={onSwipeLeft}>
                    <img
                        src={arrow}
                        alt={
                            language === 'english'
                                ? 'Swipe left'
                                : 'החליקו ימינה'
                        }
                    />
                </div>
            </div>

            <div
                className={`swipe-container-prev ${language} ${
                    !headerInView ? 'visible' : 'hidden'
                }`}
            >
                <div
                    className={`swipe-right ${language}`}
                    onClick={onSwipeRight}
                >
                    <img
                        src={arrow}
                        alt={
                            language === 'english'
                                ? 'Swipe right'
                                : 'החליקו שמאלה'
                        }
                    />
                </div>
            </div>

            <div className={`names ${language}`}>
                {language === 'english' ? 'Tom\n&Yarin' : 'טום\nוירין'}
            </div>

            <SantoriniVector />

            <div className={`details ${language}`}>
                <div className="detailsText">
                    {language === 'english'
                        ? weddingDate.toLocaleDateString(locale, options)
                        : '27 בספטמבר 2025'}
                    <br />
                    {language === 'english'
                        ? 'Santorini, Greece'
                        : 'סנטוריני, יוון'}
                </div>
                <span className={`dayCount`}>
                    {language === 'english'
                        ? 'In ' + daysLeft + ' days'
                        : 'בעוד ' + daysLeft + ' ימים'}
                </span>
            </div>
        </section>
    );
};

export default Header;
